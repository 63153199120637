// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    width: 260px;
    height: 80px;
    background: $color-2;
    margin-top: 50px;
    border-radius: 6px;
    transition: 0.3s;
    h5 {    
        font-family: $font-family-1;
        font-weight: normal;
        font-size: $font-size-16;
        color: #EDEDED;
        text-transform: uppercase;
        letter-spacing: 3.2px;
        transition: 0.3s;
    }
    &:hover {
        background: $color-white;
        transition: 0.3s;
        h5 {
            color: $color-1;
            transition: 0.3s;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        width: 200px;
    }
}


/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background:rgba(0, 0, 0, .2);//hsla(9, 100%, 64%, 0.4);//
        }
        &_caption {
            position: absolute;
            top: 40%;
            left: 5%;
            width: 100%;
            z-index: $z-index-slide-caption;
            h2 {
                font-family: $font-family-1;
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-white;
                padding-bottom: 15px;
            }
            p {
                font-size: $font-size-16;
                color: $color-white;
            }
            @media screen and (max-width: 1550px){
                h2 {
                    font-size: 2.7rem;
                }
                @media screen and (max-width: 1400px){
                    top: 35%;
                    @media screen and (max-width: $size-md-max){
                        h2 {
                            font-size: 2.5rem;
                        }
                        @media screen and (max-width: 1150px){
                            top: 30%;
                            h2 {
                                font-size: 2.3rem;
                            }
                            @media screen and (max-width: 1024px){
                                h2 {
                                    font-size: 2rem;
                                }
                                @media screen and (max-width: $size-sm-max){
                                    top: 18%;
                                    p {
                                        width: 90%;
                                        br {
                                            display: none;
                                        }
                                    }
                                    a.btn-link {
                                        width: 200px;
                                        height: 75px;
                                        margin-top: 25px;
                                    }
                                    @media screen and (max-width: $size-xs-max){
                                        top: 38%;
                                        width: 90%;
                                        h2 br {
                                            display: none;
                                        }
                                        p {
                                            width: 100%;
                                        }
                                        @media screen and (max-width: 375px){
                                            top: 25%;
                                            @media screen and (max-width: 360px){
                                                top: 23%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 100px 0 75px 0;
    .left-side {
        width: 45%;
        background: $color-1;
        padding: 175px 65px 175px 5%;
        position: absolute;
        h3 {
            font-family: $font-family-1;
            font-weight: 600;
            font-size: $font-size-26;
            color: #ECF5FD;
            line-height: 1.3;
            text-transform: initial;
            padding-bottom: 35px;
        }
        p {
            color: #EDEDED;
        }
        
    }
    .right-side {
        margin-left: auto;
    }
    @media screen and (max-width: 1875px){
        .right-side {
            width: 75%;
        }
        @media screen and (max-width: 1680px){
            .left-side {
                padding: 125px 65px 125px 5%;
            }
            @media screen and (max-width: 1550px){
                .left-side {
                    padding: 100px 65px 100px 5%;
                }
                @media screen and (max-width: 1460px){
                    .left-side {
                        padding: 75px 65px 75px 5%;
                    }
                    @media screen and (max-width: 1400px){
                        flex-direction: column-reverse;
                        padding: 100px 5% 75px 5%;
                        .left-side {
                            width: 75%;
                            padding: 75px 50px;
                            margin: auto;
                            position: initial;
                        }
                        .right-side {
                            width: 75%;
                            margin: auto;
                        }
                        @media screen and (max-width: $size-sm-max){
                            padding: 100px 5% 0 5%;
                            .left-side, .right-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max){
                                .left-side {
                                    padding: 75px 5%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-title-carousel {
    .title {
        padding: 100px 5%;
        h4 {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-60;
            color: $color-1;
            text-transform: initial;
        }
    }
    .section-carousel {
        margin: 75px 0 100px 0;
        .main-content {
            position: relative;
            .item {
                .col-xs-12.txt {
                    padding-left: 0;
                    padding-right: 0; 
                    .text {
                        background: #EDEDED;
                        padding: 15px 20px;
                        h5 {
                            font-family: $font-family-1;
                            font-weight: normal;
                            font-size: $font-size-20;
                            color: $color-3;
                        }
                    }
                }
            }   
            .owl-nav {
                position: absolute;
                top: -12%;
                transform: translateY(-50%);
                z-index: 1;
                width: 100%;
                padding: 0 5%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }   
        }
    }
    @media screen and (max-width: 1600px){
        .title {
            h4 {
                font-size: 2.5rem;
            }
        }
        @media screen and (max-width: 1024px){
            .title {
                h4 {
                    font-size: 2rem;
                }
            }
            @media screen and (max-width: $size-sm-max){
                .section-carousel {
                    .main-content {
                        .owl-nav {
                            top: -15%;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max){
                    .title h4 {
                        text-align: center;
                        br {
                            display: none;
                        }
                    }
                    .section-carousel {
                        margin: 75px 5% 100px 5%;
                        .main-content .owl-nav {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

#section-services {
    padding: 60px 5% 75px 5%;
    background: #ECF5FD;
    margin-bottom: 60px;
    .section-title {
        h3 {
            font-family: $font-family-3;
            font-weight: normal;
            font-size: $font-size-60;
            color: $color-1;
            text-transform: initial;
        }
        a.link {
            font-family: $font-family-1;
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-1;
            border-bottom: 1px solid $color-1;
        }
    }
    .section-bloc {
        padding-top: 55px;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 15px;
        //align-items: start;
        //justify-items: center;
        @media screen and (max-width: 1400px){
            grid-template-columns: repeat(3, 1fr);
            @media screen and (max-width: $size-sm-max){
                grid-template-columns: repeat(2, 1fr);
                @media screen and (max-width: $size-xs-max){
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
        .bloc {
            padding: 75px 15px;
            height: 100%;
            min-height: 310px;
            background: $color-1;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title {
                margin-bottom: 50px;
                padding: 0;
                h2 {
                    font-family: $font-family-3;
                    font-size: $font-size-120;
                    font-weight: normal;
                    color: $color-white;
                } 
            }
            .text {
                h4 {
                    font-family: $font-family-3;
                    font-size: $font-size-24;
                    font-weight: normal;
                    text-align: center;
                    color: $color-white;
                }
            }
        }
    }
    @media screen and (max-width: 1761px){
        .section-bloc .bloc .text h4 {
            font-size: 1.1rem;
        }
        @media screen and (max-width: 1635px){
            .section-bloc .bloc .text h4 {
                font-size: 1rem;
            }
            @media screen and (max-width: 1400px){
                .section-bloc .bloc .text h4 {
                    font-size: 1.2rem;
                }
                @media screen and (max-width: $size-md-max){
                    .section-title h3 {
                        font-size: 2.5rem;
                    }
                    @media screen and (max-width: $size-sm-max){
                        .section-title h3 {
                            font-size: 2rem;
                        }
                        @media screen and (max-width: $size-xs-max){
                            .section-title {
                                flex-direction: column;
                                align-items: initial;
                                h3 {
                                    padding-bottom: 20px;
                               }
                            }
                            .section-bloc {
                                .bloc {
                                    .title {
                                        h2 {
                                            font-size: 7rem;
                                        }
                                    }
                                    .text h4 {
                                        font-size: 1.5rem;
                                    }
                                }
                            }
                            @media screen and (max-width: 375px){
                                .section-title h3 {
                                    font-size: 1.85rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* USINAGE
*******************************************************************************/

#section-bloc-image {
    padding: 100px 0 0 5%;
    margin-bottom: 100px;
    position: relative;
    .left-side {
        width: 35%;
        margin-right: 15px;
        .contenu {
            background: #EDEDED;
            padding: 100px 35px 65px 35px;
            h4 {
                font-weight: bold;
                font-size: $font-size-26;
                padding-bottom: 20px;
                color: #231F20;
                line-height: 1.3;
            }
            p {
                color: #231F20
            }
        }
    }
    .right-side {
        width: 65%;
        background: url("../images/usinage_section01_img01.jpg") no-repeat;
        background-size: cover;
    }
    &::before {
        content: '';
        background: $color-1;
        width: 38%;
        height: 82%;
        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 0;
    }
    @media screen and (max-width: 1875px){
        &::before {
            width: 37.95%;
        }
        @media screen and (max-width: 1750px){
            .left-side .contenu {
                padding: 60px 35px 50px 35px;
            }

            @media screen and (max-width: 1630px){
                .left-side .contenu {
                    padding: 50px 35px;
                }
                &::before {
                    width: 37.89%;
                }
                @media screen and (max-width: 1465px){
                    .left-side .contenu {
                        padding: 100px 35px;
                    }
                    &::before {
                        width: 36.95%;
                    }
                    @media screen and (max-width: $size-md-max){
                        padding: 100px 5%;
                        margin-bottom: 0;
                        flex-direction: column-reverse;
                        .left-side {
                            width: 75%;
                            margin: 15px auto 0 auto;
                            .contenu {
                                padding: 60px 35px;
                            }
                        }
                        .right-side {
                            width: 75%;
                            margin: auto;
                        }
                        &::before {
                            display: none;
                        }
                        @media screen and (max-width: $size-sm-max){
                            .left-side, .right-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max){
                                .left-side .contenu {
                                    padding: 55px 5%;
                                }
                                .right-side {
                                    background: url("../images/usinage_section01_img01_m.jpg") no-repeat;
                                    background-size: cover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-background {
    background: #ECF5FD;
    padding: 50px 5%;
    .left-side {
        width: 45%;
        margin-right: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            text-transform: initial;
            padding-bottom: 35px;
            color: #054F88;
            line-height: 1.3;
        }
        
    }
    .right-side {
        width: 60%;  
    }
    @media screen and (max-width: 1340px) {
        .left-side h3 br {
            display: none;
        }
        @media screen and (max-width: 1250px) {
            flex-direction: column-reverse;
            .left-side {
                width: 100%;
                margin-right: 0;
            }
            .right-side {
                width: 75%;
                margin: 0 0 50px 0;
            }
            @media screen and (max-width: $size-sm-max) {
                .right-side {
                    width: 100%;
                }
            }
        }
    }
}

#section-image-text {
    padding: 100px 0;
    .image-side {
        width: 55%;
        background: url("../images/usinage_section03_img01.jpg") no-repeat;
        background-size: cover;
    }
    .text-side {
        width: 45%;
        background: #EDEDED;
        padding: 50px 5% 50px 55px;
        h4 {
            font-weight: bold;
            font-size: $font-size-26;
            color: $color-1;
            margin-bottom: 55px;
        }
    }
    @media screen and (max-width: 1250px) {
        flex-direction: column;
        padding: 100px 5%;
        .image-side, .text-side {
            width: 75%;
            margin: auto;
        }
        .text-side {
            padding: 60px 35px;
        }
        @media screen and (max-width: $size-sm-max) {
            .image-side, .text-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .image-side {
                    background: url("../images/usinage_section03_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
                .text-side {
                    padding: 60px 5%;
                }
            }
        }
    }
}

#section-image-text-2 {
    padding: 0 5%;
    .image-side {
        width: 50%;
        background: url("../images/usinage_section04_img01.jpg") no-repeat;
        background-size: cover;
    }
    .text-side {
        width: 50%;
        background: $color-1;
        padding: 50px 55px;
        h4 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #EDEDED;
            padding-bottom: 25px;
            line-height: 1.3;
            border-bottom: 2px solid #EDEDED;
            margin-bottom: 60px;
        }
        h5 {
            font-family: $font-family-2;
            font-weight: bold;
            font-size: $font-size-20;
            color: #EDEDED;
            padding-bottom: 12px;
        }
        p {
            color: #EDEDED;
        }
    }
    @media screen and (max-width: 1530px) {
        .text-side h4 br {
            display: none;
        }
        @media screen and (max-width: 1250px) {
            flex-direction: column;
            .image-side, .text-side {
                width: 75%;
                margin: auto;
            }
            .text-side {
                padding: 60px 35px;
            }
            @media screen and (max-width: $size-sm-max) {
                .image-side, .text-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .image-side {
                        background: url("../images/usinage_section04_img01_m.jpg") no-repeat;
                        background-size: cover;
                    }
                    .text-side {
                        padding: 60px 5%;
                    }
                }
            }
        }
    }
}

#section-bloc {
    padding: 130px 5% 75px 5%;
    .left-side {
        width: 50%;
        background: #054F881A;
        border-radius: 30px;
        margin-right: 15px;
        padding: 93px 45px;
        h4 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #054F88;
            padding-bottom: 45px;
        }
    }
    .right-side {
        width: 50%;
        background: #054F881A;
        border-radius: 30px;
        padding: 93px 140px;
        h4 {
            font-weight: bold;
            font-size: $font-size-30;
            color: #054F88;
            padding-bottom: 75px;
        }
        ul {
            li {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-18;
                text-transform: initial;
                line-height: 1.5;
                margin: 0;
                color: $color-3;
            }
        }
    }
    @media screen and (max-width: 1835px) {
        .right-side {
            padding: 93px 100px;
        }
        @media screen and (max-width: 1658px) {
            .right-side {
                padding: 93px 45px;
            }
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                .left-side, .right-side {
                    width: 100%;
                }
                .left-side {
                    margin: 0 0 15px 0;
                }
                @media screen and (max-width: $size-sm-max) {
                    padding: 100px 5% 75px 5%;
                    @media screen and (max-width: $size-xs-max) {
                        .left-side, .right-side {
                            padding: 75px 5%;
                        }
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 50px 5% 70px 5%;
    h3 {
        font-weight: 500;
        font-size: $font-size-26;
        color: $color-1;
        text-transform: initial;
        padding-bottom: 60px;
    }
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
    }
    #blocIntro_promotions {
        h3 {
            color: $color-1;
            line-height: 1.3;
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    padding: 75px 0 35px 0;
    margin: 0 5%;
    border-bottom: 1px solid $color-1;
    .left-side {
        width: 38%;
        margin-right: 15%;
        h4 {
            font-family: $font-family-2;
            font-weight: 500;
            font-size: $font-size-20;
            color: $color-1;
            padding-bottom: 5px;
        }
        h5, a {
            font-family: $font-family-2;
            font-weight: normal;
            font-size: $font-size-16;
            color: $color-1;
            padding-bottom: 15px;
        }
    }
    .right-side {
        width: 65%;
        h4 {
            font-family: $font-family-2;
            font-weight: 500;
            font-size: $font-size-20;
            color: $color-1;
            padding-bottom: 15px;
        }
        h5, a {
            font-family: $font-family-2;
            font-weight: 500;
            font-size: $font-size-16;
            color: #777475;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .left-side {
            width: 85%;
            margin: 0 0 35px 0;
        }
        .right-side {
            width: 75%;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side {
                width: 100%;
            }
            .right-side {
                width: 95%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    h4 {
                        font-size: 1.2rem;
                    }
                    h5, a {
                        font-size: 1rem;
                    }
                }
                .right-side {
                    width: 100%;
                    flex-direction: column;
                    h4 {
                        font-size: 1.2rem;
                        padding-bottom: 10px;
                    }
                    h5, a {
                        font-size: 1rem;
                    }
                    .bloc.second {
                        padding: 25px 0;
                    }
                }
            }
        }
    }
}

#section-heures-form {
    padding: 50px 5%;
    .left-side {
        width: 40%;
        margin-right: 150px;
        h3 {
            font-family: $font-family-2;
            font-weight: 500;
            font-size: $font-size-20;
            color: $color-1;
            padding-bottom: 40px;
        }
        .jours-heures {
            h4 {
                font-family: $font-family-2;
                font-weight: 500;
                font-size: $font-size-16;
                color: $color-1;
                line-height: 3;
            }
            h5 {
                font-family: $font-family-2;
                font-weight: normal;
                font-size: $font-size-16;
                color: $color-1;
                line-height: 3;
            }
            .jour {
                width: 75%;
            }
        }
    }
    .right-side {
        width: 60%;
    }
    @media screen and (max-width: 1645px) {
        .left-side .jours-heures .jour {
            width: 65%;
        }
        @media screen and (max-width: 1200px) {
            flex-direction: column;
            .left-side {
                width: 45%;
                margin: 0 0 50px 0;
            }
            .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    width: 100%;
                }
                .form-group.col-xs-12.col-sm-6.pl0, .form-group.col-xs-12.col-sm-6.px0 {
                    padding: 0;
                }
            }
        }
    }
}

#section-map {
    padding: 55px 5%;
}

#content {
    h3#firstHeading {
        color: $color-2;
        padding-bottom: 5px;
    }
    p a {
        color: $color-1;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
    .popup-box img {
        width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
   

}
