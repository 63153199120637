$font-size-10: 0.5rem;
$font-size-12: 0.6rem;
$font-size-14: 0.7rem;
$font-size-16: 0.8rem;
$font-size-18: 0.9rem;
$font-size-20: 1.0rem;
$font-size-22: 1.1rem;
$font-size-24: 1.2rem;
$font-size-26: 1.3rem;
$font-size-28: 1.4rem;
$font-size-30: 1.5rem;
$font-size-32: 1.6rem;
$font-size-34: 1.7rem;
$font-size-36: 1.8rem;
$font-size-38: 1.9rem;
$font-size-40: 2.0rem;
$font-size-42: 2.1rem;
$font-size-44: 2.2rem;
$font-size-46: 2.3rem;
$font-size-48: 2.4rem;
$font-size-50: 2.5rem;
$font-size-60: 3.0rem;
$font-size-64: 3.2rem;
$font-size-70: 3.5rem;
$font-size-80: 4.0rem;
$font-size-90: 4.5rem;
$font-size-100: 5.0rem;
$font-size-120: 6.0rem;