.btn {
    background-color: $color-2;
    border: 1px solid $color-2;
    border-radius: 10px;

    font-weight: normal;
    font-size: $font-size-16;
    color: #EDEDED;
    text-transform: uppercase;
    letter-spacing: 3.2px;

    width: 260px;
    height: 80px;
    transition: all 0.2s ease-in;


    &:hover {
        transition: all 0.2s ease-in;
        background-color: transparent;
        color: $color-1;
        border: 1px solid $color-1;
    }
}
