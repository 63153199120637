footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: 0 5%;
        display: flex;
        justify-content: space-between;
        @extend .items-center;
        @extend .text-color-grey;
        @extend .font-footer;

        .left-side {
            padding-right: 10%;
            .logo {
                padding-bottom: 25px;
            }
            .copyright {
                font-family: $font-family-1;
                font-weight: normal;
                font-size: $font-size-16;
                color: #EDEDED;
            }
        }
        .center-side {
            width: 55%;
            .menu-footer {
                padding-right: 100px;
                width: 65%;
                a.link {
                    font-family: $font-family-1;
                    font-weight: normal;
                    font-size: $font-size-14;
                    color: #EDEDED;
                    text-transform: uppercase;
                    transition: 0.3s;
                    width: 50%;
                    padding: 0 0 25px 0;
                    &:hover {
                        color: $color-2;
                        transition: 0.3s;
                    }
                    &.last {
                        padding: 0;
                    }
                }
            }
        }
        @media screen and (max-width: 1200px){
            flex-direction: column;
            align-items: initial;
            .left-side {
                padding: 0 0 50px 0;
            }
            .center-side {
                width: 100%;
                flex-direction: column;
                align-items: initial;
                padding-bottom: 35px;
                .menu-footer {
                    padding-right: 0;
                    width: 45%;
                    margin-bottom: 50px;
                }
            }
            @media screen and (max-width: $size-sm-max){
                .center-side {
                    .menu-footer {
                        width: 63%;
                        a.link {
                            font-size: 0.9rem;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max){
                    .center-side {
                        .menu-footer {
                            width: 100%;
                            margin-bottom: 35px;
                            a.link {
                                width: 100%;
                                &.last {
                                    padding: 0 0 25px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px){
        min-height: 415px;
        @media screen and (max-width: $size-xs-max){
            min-height: 515px;
        }
    }
}
